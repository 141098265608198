import React from "react";
import BuildingCard from "./BuildingCard";

const Activity = () => {
  return (
    <div className="bg-bk-blue ">
      <div className="max-w-[1140px] mx-auto  pb-[60px]">
        <div className="md:text-[48px] text-[24px] text-white flex justify-center sm:mx-0 mx-5 pt-12 ">Building Company </div>
        <div className="text-white text-center pt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div>
        <div className="pt-10">
          <div className="grid gap-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 sm:px-0 px-4">
            <BuildingCard title="DESIGN-BUILD" />
            <BuildingCard />
            <BuildingCard title="CITY SPACES" />
            <BuildingCard title="CIVIL ENGINEERS" />
            <BuildingCard title="SPACES" />
            <BuildingCard title="HOUSING" />
            <BuildingCard title="ARCHITECTURE" />
            <BuildingCard title="ABOUT US" />
          </div>
        </div>
        <div className="pt-8">
          <div className="text-center">
            <div className="text-white">
              Image by <span className="underline decoration-1">Saikhanbileg</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
