
import { Fragment } from "react";
import "./App.css";
import Home from "./pages/home"
import { LayoutComponent } from "./components/";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route path="/" element={<LayoutComponent />}>
        <Route index element={<Home />} />
        {/* <Route path="/user/auth" element={<Login />} /> */}
      </Route>
      <Route path="*" element={<div>Page not found 404</div>}></Route>
    </Fragment>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
