import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import NavigationHeader from "./navigation/NavigationHeader";
import Footer from "./layout/Footer";

const LayoutComponent = () => {
  return (
    <Fragment>
      <div className={`tracking-tight font-roboto `}>
        {/* <NavigationHeader /> */}
        {/* {showMenu && <Offcanvas />} */}
        {/* <div className="sticky top-0 left-0 w-full z-10 bg-white">
          <Header />
          <Navbar />
        </div> */}
        <div className="">
          <Outlet />
          <Footer />
        </div>
      </div>
      {/* <div className={`${showMenu ? "bg-black fixed bg-opacity-[0.4] z-20 w-full h-screen top-0 left-0" : ""} `}></div> */}
    </Fragment>
  );
};

export default LayoutComponent;
