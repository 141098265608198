import React, { useState } from "react";
import DesignComponent from "./DesignComponent";
const ConstructionCard = () => {
  return (
    <div className="bg-bk-blue pt-[100px]">
      <div className="md:text-[48px] text-[24px] text-white flex justify-center sm:mx-0 mx-5">We maximize added value for our clients</div>
      <div className="flex justify-center">
        <div className="grid lg:grid-cols-4 gap-3 sm:grid-cols-2 grid-cols-1">
          <DesignComponent />
          <DesignComponent />
          <DesignComponent />
          <DesignComponent />
        </div>
      </div>
    </div>
  );
};

export default ConstructionCard;
