import React from 'react'
import CommentCard from './CommentCard'

const WhyUs = () => {
    return (
        <div className='bg-white py-[60px]'>
            <div className='flex justify-center'>
                <div className='grid sm:grid-cols-2 w-[1140px] gap-[60px] sm:px-0 px-[30px]'>
                    <div>
                        <div className='text-[60px]'>
                            Why Us?
                        </div>
                        <div className='text-[20px] font-lightbold pt-7'>
                            Our customers choose us because we provide leading technology, deliver unmatched services and training, and offer some of the best value in the industry.
                        </div>
                        <div className='text-[20px] font-light pt-5'>
                            Sample text. Click to Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Pharetra magna ac placerat vestibulum. Quam lacus suspendisse faucibus interdum posuere lorem. Egestas tellus rutrum tellus pellentesque eu tincidunt. Neque vitae tempus quam pellentesque nec. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi. Interdum velit euismod in pellentesque massa placerat duis.
                        </div>
                        <div className="pt-8">
                            <div className="text-left">
                                <div className="text-black font-semibold">
                                    Image by <span className="underline decoration-1">Saikhanbileg</span>
                                </div>
                            </div>
                        </div>
                        <div className='py-5'>
                            <button className='text-white  text-[20px] px-[67px] py-[21px] border-black border-[1px] hover:bg-white hover:text-black bg-bk-blue'>
                                LEARN MORE
                            </button>
                        </div>

                    </div>
                    <div>
                       
                        <CommentCard/>
                        <CommentCard/>
                        <CommentCard/>
                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs