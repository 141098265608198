import React from "react";

import buildIcon from "../../assets/images/buildIcon.png";
const DesignComponent = () => {
  return (
    <div className="py-[35px] px-5 bg-white w-[280px]">
      <div className="relative">
        <div className="bg-[#f1c50e] rounded-full sm:w-[118px] sm:h-[118px] opacity-80 absolute left-[25%] top-0"></div>
        <div className="pt-10 pl-2 sm:block flex justify-center">
          <img src={buildIcon} className="max-h-[96px] max-w-[96px]" alt="buildIcon" />
        </div>
        <div className="mt-7 text-[24px] text-center">DESIGN-BUILD</div>

        <div className="text-[12px] text-grey-50 mx-3 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit nullam nunc justo sagittis suscipit ultrices.</div>
      </div>
    </div>
  );
};

export default DesignComponent;
