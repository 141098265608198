import React from "react";
import MainBanner from "../../components/banner/MainBanner";
import HomeCard from "../../components/card/HomeCard";
import ImageDescCard from "../../components/card/ImageDescCard";
import Activity from "../../components/card/Activity";
import CompanyAbout from "../../components/card/CompanyAbout";
import ArchitechturalContent from "../../components/card/ArchitechturalContent";
import WhyUs from "../../components/card/WhyUs";

const index = () => {

  return (
    <div className="">
      <MainBanner />
      <div className=""></div>

      <div className="">
        <HomeCard />
      </div>

      <div className="">
        <ImageDescCard />
      </div>
      <div className="">
        <Activity />
      </div>
      <div>
        <CompanyAbout />
      </div>
      <div className="">
        <ArchitechturalContent />
      </div>
      <div>
        <WhyUs />
      </div>
    </div>
  );
};

export default index;
