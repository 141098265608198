import React, { useState, useEffect } from "react";
import CountUp from "react-countup";

const CompanyAbout = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isWatching, setIsWatching] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position >= 2400) {
      setIsWatching(true);
    } else {
      if (isWatching) {
        setIsWatching(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="max-w-[1140px] mx-auto pt-[30px] pb-[60px]">
      <div className="w-full">
        <div className="flex flex-col-reverse justify-center items-center lg:flex-row">
          <div className="py-[30px] pl-[30px] pr-[50px] w-full lg:w-[50%]">
            <div className="text-[30px]">Investing in parks and flexible streets can create healthier cities.</div>
            <div className="pt-5 text-[20px]">
              {" "}
              Designers and urbanists will continue to prioritize pedestrian-oriented urban spaces over car-centric developments, which means outdoor spaces will be a key ingredient.
            </div>
            <div className="pt-[50px]">
              Image by <span className="underline decoration-1">Saikhanbileg</span>
            </div>
            <div className="pt-[50px]">
              <button className="bg-bk-blue hover:bg-black text-white text-[32px] py-5 px-[60px]">LEARN MORE</button>
            </div>
          </div>

          <div className="h-[470px] w-full lg:w-[50%] bg-norepeat bg-center bg-cover bg-[url(https://assets.nicepagecdn.com/d2cc3eaa/3652882/images/architect-showing-his-work-4.jpg)]"></div>
        </div>
        <div className="py-[60px]">
          {isWatching && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="text-center">
                <h2 className="text-[96px] text-bk-blue font-semibold tracking-tight">
                  <CountUp end={120} duration={3} />
                </h2>
                <div className="pt-[10px] text-[28px]">PROJECTS</div>
              </div>
              <div className="text-center">
                <h2 className="text-[96px] text-bk-blue font-semibold tracking-tight">
                  <CountUp end={45} duration={4} />
                </h2>
                <div className="pt-[10px] text-[28px]">OFFICES</div>
              </div>
              <div className="text-center">
                <h2 className="text-[96px] text-bk-blue font-semibold tracking-tight">
                  <CountUp end={50} duration={5} />
                </h2>
                <div className="pt-[10px] text-[28px]">AWARDS</div>
              </div>
              <div className="text-center">
                <h2 className="text-[96px] text-bk-blue font-semibold tracking-tight">
                  <CountUp end={10000} duration={6} />
                </h2>
                <div className="pt-[10px] text-[28px]">CLIENTS</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyAbout;
