import React, { useEffect, useRef } from "react";
import Dots from "../../assets/images/dots.png";
import Architects from "../../assets/images/architects.jpg";
import "swiper/css";

const MainBanner = () => {
  const yellowRef = useRef(null);
  const dotsRef = useRef(null);
  const cardRef = useRef(null);
  const archiRef = useRef(null);

  useEffect(() => {
    yellowRef.current.classList.remove("w-[50px]");
    yellowRef.current.classList.remove("sm:w-[200px]");
    archiRef.current.classList.remove("translate-x-[500px]");
    cardRef.current.classList.remove("opacity-0");
    cardRef.current.classList.remove("sm:bottom-[-250px]");
    dotsRef.current.classList.remove("ml-[-300px]");

    cardRef.current.classList.add("bottom-[100px]");
    yellowRef.current.classList.add("w-full");
    yellowRef.current.classList.add("h-full");
    yellowRef.current.classList.add("sm:w-[500px]");
  }, []);

  return (
    <div className=" h-full w-full bg-[#e0e5eb] py-[50px]">
      <div className="flex items-center h-full relative sm:justify-center">

        <img
          ref={dotsRef}
          src={Dots}
          alt="Dots"
          className="md:mr-2 md:block hidden ml-[-300px] transition-all duration-1000 ease-out"
        />
        <div className="z-20 relative">

          <img
            ref={archiRef}
            src={Architects}
            className=" max-w-full sm:max-w-[1024px] duration-[2000ms] md:py-[50px]
                ease-out translate-x-[500px] transition-all"
            alt="architects"
          />
          <div
            className="bg-white shadow-lg p-6 sm:p-[30px] sm:absolute static bottom-10 sm:bottom-[0] right-10 sm:right-[0px] z-30 max-w-[450px] transition-all duration-[2000ms]
                ease-out opacity-0"
            ref={cardRef}
          >
            <div className="text-4xl sm:text-6xl font-light">Architecture Firm</div>
            <div className="leading-7 pt-4 sm:pt-[30px] font-light">
              Massa ultricies mi quis hendrerit. Ac ut consequat semper viverra nam. Libero id faucibus nisl tincidunt eget nullam non nisi est. Arcu odio ut sem nulla.
            </div>
            <div className="pt-4 sm:pt-[30px]">
              Image by <span className="underline decoration-1">Saikhanbileg</span>
            </div>
            <div className="pt-8 sm:pt-[50px]">
              <button className="text-xl bg-bk-blue text-white px-8 sm:px-[68px] py-3 sm:py-5">LEARN MORE</button>
            </div>
          </div>
        </div>
        <div
          ref={yellowRef}
          className="w-[50px] sm:w-[200px] sm:bg-[#f1c50e] h-full z-10 absolute transition-all duration-1000 
                ease-out"
        ></div>

      </div>

    </div>
  );
};

export default MainBanner;
