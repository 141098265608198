import React from "react";
import maleArchitects from "../../assets/images/maleArchitects.jpg";
import correctIcon from "../../assets/images/correctIcon.png";

const ImageDescCard = () => {
  return (
    <div className="md:p-[100px] px-[20px] py-[20px]">
      <div className="max-w-[1140px] mx-auto">
        <div className="md:flex w-full flex-col md:flex-row">
          <div className="w-full">
            <div className="text-[32px] font-semibold">Our company has many years of experience!</div>
            <div className="pt-4">
              <img className="md:max-w-[510px]" src={maleArchitects} alt="alt" />
            </div>
            <div className="py-4">
              Our team is dedicated to creating a more equ Connecting people, neighborhoods, cities, systems, and ways of living across cultures. We are inspired by our environment and the potential
              of when people come together.table future through good design.
            </div>
            <div className="py-2">
            <button className="border-[1px] px-7 py-2 hover:bg-bk-blue hover:text-white">
                Read more
            </button>
            </div>
          </div>
          <div className="w-full text-left bg-[#ece9e9] flex flex-col justify-center">
            <div className="flex items-center px-6 py-5">
              <img src={correctIcon} alt="correct Icon" className="max-w-[50px] mr-8" />
              <p className=" text-[32px] font-semibold">Duis aute irure</p>
            </div>
            <div className="flex items-center px-6 py-5">
              <img src={correctIcon} alt="correct Icon" className="max-w-[50px] mr-8" />
              <p className=" text-[32px] font-semibold">Duis aute irure</p>
            </div>
            <div className="flex items-center px-6 py-5">
              <img src={correctIcon} alt="correct Icon" className="max-w-[50px] mr-8" />
              <p className=" text-[32px] font-semibold">Duis aute irure</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDescCard;
