import React from "react";

const Footer = () => {
  return (
    <footer className="bg-bk-blue text-white">
      <div className="md:max-w-xl mx-auto max-w-xs">
        <div className="py-[60px]">
          <div className="text-white">
            <h4 className="text-[48px] w-full text-center ">Contact Us</h4>
            <div className="">
              <div className="flex justify-center gap-6">
                <div className="w-full">
                  <label className="block">Email</label>
                  <input className="py-2 px-2  w-full" type="text" placeholder="enter a valit email address" />
                </div>
                <div className="w-full">
                  <label className="block">Name</label>
                  <input className="py-2 px-2  w-full" type="text" placeholder="enter your name" />
                </div>
              </div>
              <div className="">
                <div className="pt-4">
                  <button className="w-full text-center text-black bg-[#f1c50e] hover:bg-white p-3 font-semibold ">SUBMIT</button>
                </div>
              </div>
              <div className="grid grid-cols-3 mt-8 gap-8">
                <div className="">
                  <div className="font-bold text-white text-[20px] text-center">FIND US</div>
                  <div className="font-normal text-white text-center ">121 Rock Sreet, 21 Avenue, New York, NY 92103-9000</div>
                </div>
                <div className="">
                  <div className="font-bold text-white text-[20px] text-center">HOURS </div>
                  <div className="font-normal text-white text-center ">
                    Monday - Friday <br/>9am – 6pm<br/>
                   Saturday -  Sunday<br/> 9am - 6pm
                  </div>
                </div>
                <div className="">
                  <div className="font-bold text-white text-[20px] text-center">CALL US </div>
                  <div className="font-normal text-white text-center ">77495599 <br></br> 98051517</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
