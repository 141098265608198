import React from "react";
import buildingCompany from "../../assets/images/buildingCompany.jpg";

const BuildingCard = ({title ="REMODELING"}) => {
  return (
    <div className="bg-white p-7">
      <div className="flex justify-center">
        <img src={buildingCompany} className="sm:max-h-[185px]" alt="building-company" />
      </div>
      <div className="pt-8">
        <div className="text-[24px] font-lightbold text-center">{title}</div>
        <div className="text-[14px] pt-5 text-center text-gray">Sample text. Click to select the text box. Click again or double click to start editing the text.</div>
        <div className="pt-5 text-center">
            <button className="underline underline-offset-4 text-[16px] font-light">MORE</button>
        </div>
      </div>
    </div>
  );
};

export default BuildingCard;
